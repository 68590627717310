import { computed, inject, Injectable, signal } from '@angular/core'
import { JsonLogicService } from './json-logic.service'
import { FilterId, IFilter, IFilterCommands, IFilterSettings, IIsFilters, INotFilters } from '../interfaces/filter.interface'
import { IJsonLogic } from '../interfaces/json-logic.interface'
import { checkRangeFilterEmpty, getEmptyRangeFilterState } from 'src/app/components/filters/range/range-filter.component'
import { checkZipCodeFilterEmpty, getEmptyZipCodeFilterState } from 'src/app/components/filters/zip-code/zip-code-filter.component'
import { checkDrilldownFilterEmpty, getEmptyDrilldownFilterState } from 'src/app/components/filters/drilldown/drilldown-filter.component'
import { Buffer } from 'buffer'

function getDefaultFilters(): IFilter[] {
  return [{
    id: FilterId.MakeModel,
    state: getEmptyDrilldownFilterState(),
    isEmpty: checkDrilldownFilterEmpty,
  }, {
    id: FilterId.CatSubcat,
    state: getEmptyDrilldownFilterState(),
    isEmpty: checkDrilldownFilterEmpty,
  }, {
    id: FilterId.Year,
    state: getEmptyDrilldownFilterState(),
    isEmpty: checkDrilldownFilterEmpty,
  }, {
    id: FilterId.Location,
    state: getEmptyDrilldownFilterState(),
    isEmpty: checkDrilldownFilterEmpty,
  }, {
    id: FilterId.PriceRange,
    state: getEmptyRangeFilterState(),
    isEmpty: checkRangeFilterEmpty,
  }, {
    id: FilterId.MilesRange,
    state: getEmptyRangeFilterState(),
    isEmpty: checkRangeFilterEmpty,
  }, {
    id: FilterId.HoursRange,
    state: getEmptyRangeFilterState(),
    isEmpty: checkRangeFilterEmpty,
  }, {
    id: FilterId.ZipCode,
    state: getEmptyZipCodeFilterState(),
    isEmpty: checkZipCodeFilterEmpty,
  }]
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _jsonLogicService = inject(JsonLogicService)
  filterSettings: IFilterSettings = {}
  filters = signal<IFilter[]>(getDefaultFilters())

  private _is: IIsFilters = {
    listed: {
      ...this._jsonLogicService.equals(
        this._jsonLogicService.var('filter_is_listed'),
        'true',
      )
    },
    featured: {
      ...this._jsonLogicService.equals(
        this._jsonLogicService.var('filter_is_featured'),
        'true',
      )
    },
    equipment: (id: string) => ({
      ...this._jsonLogicService.equals(
        this._jsonLogicService.var('equipment_number'),
        id,
      )
    }),
  }

  private _not: INotFilters = {
    featured: {
      ...this._jsonLogicService.doesNotEqual(
        this._jsonLogicService.var('filter_is_featured'),
        'true',
      )
    },
    equipment: (id: string) => ({
      ...this._jsonLogicService.doesNotEqual(
        this._jsonLogicService.var('equipment_number'),
        id,
      )
    }),
  }

  build(
    processor: (commands: IFilterCommands) => IJsonLogic,
  ) {
    const result = processor({
      and: this._jsonLogicService.and,
      or: this._jsonLogicService.or,
      inArray: this._jsonLogicService.inArray,
      variable: this._jsonLogicService.var,
      equals: this._jsonLogicService.equals,
      doesNotEqual: this._jsonLogicService.doesNotEqual,
      lt: this._jsonLogicService.lt,
      lte: this._jsonLogicService.lte,
      gt: this._jsonLogicService.gt,
      gte: this._jsonLogicService.gte,
      is: this._is,
      not: this._not,
    })
    return result
  }

  hasAnyFilters(): boolean {
    return this.filters().some(f => !f.isEmpty(f.state))
  }

  hasFilter(filterId: FilterId): boolean {
    const filter = this.filters().find(f => f.id === filterId)
    if (filter) {
      return !filter.isEmpty(filter.state)
    }
    return false
  }

  getFiltersWithState() {
    return this.filters().filter(f => !f.isEmpty(f.state))
  }

  loadFilters(encodedFiltersData: string | null): void {
    if (encodedFiltersData) {
      const decodedFilters: IFilter[] = this.decodeFilters(encodedFiltersData) ?? []
      decodedFilters.forEach(decodedFilter => {
        this.updateFilter(decodedFilter.id ?? null, decodedFilter.state)
      })
    }
  }

  updateFilter(filterId: FilterId | null, newFilterState: any): void {
    this.filters.update(filters => {
      const filter = filters.find(f => f.id === filterId)
      if (filter) {
        filter.state = newFilterState
      }
      return filters
    })
  }

  clearFilterState(): void {
    this.filters.set(getDefaultFilters())
  }

  getFilter(filterId: FilterId): IFilter {
    return this.filters().find(f => f.id === filterId) ?? { id: FilterId.Unknown, state: null, isEmpty: () => true } as IFilter
  }

  encodeFilters(filters: IFilter[]): string {
    const encoder = new TextEncoder()
    const bytes = encoder.encode(JSON.stringify(filters))

    // Convert the Uint8Array to a Base64 string
    return btoa(String.fromCharCode(...bytes))
  }

  decodeFilters(filters: string): any {
    const buffer = Buffer.from(filters, 'base64')
    const str = buffer.toString('utf-8')
    return JSON.parse(str)
  }
}
