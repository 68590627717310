import { CommonModule } from '@angular/common'
import { Component, OnInit, computed, inject, input, model, signal } from '@angular/core'
import { FilterService } from 'src/app/core/services/filter.service'
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter } from 'rxjs'
import { FilterId, IFilterItem } from 'src/app/core/interfaces/filter.interface'
import { LogService } from 'src/app/core/services/log.service'
import { IJsonLogic } from 'src/app/core/interfaces/json-logic.interface'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { IconButtonModule } from '../../icon-button/icon-button.module'
import { IDrilldownFilterItem, IDrilldownTierConfig, IDrilldownTiersConfig } from 'src/app/core/interfaces/drilldown-filter.interface'


export function getEmptyDrilldownFilterState(): IDrilldownFilterState {
  return []
}

export const checkDrilldownFilterEmpty = (state: IDrilldownFilterState) => state.length === 0

type IDrilldownFilterState = IDrilldownFilterItem[]

interface IDrilldownChipData {
  item: IDrilldownFilterItem
}

@Component({
  selector: 'app-drilldown-filter',
  templateUrl: './drilldown-filter.component.html',
  styleUrls: ['./drilldown-filter.component.scss'],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    IconButtonModule,
  ],
})
export class DrilldownFilterComponent implements OnInit {
  private _logger = inject(LogService)
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)
  protected filterService = inject(FilterService)

  filterId = input.required<FilterId>()
  settings = input.required<IFilterItem[]>()
  fieldsConfig = input.required<IDrilldownTiersConfig>()
  includeSearch = input<boolean>(false)
  state = model.required<IDrilldownFilterState>()
  filters = model<IJsonLogic>()
  delimiter = input<string>('|')

  decoratedSettings = computed(() => {
    const decorateSettings = (
      settings: IFilterItem[],
      parentId: string | null = null,
    ): IDrilldownFilterItem[] => {
      return settings?.filter(setting => !!setting.value).map(setting => {
        const result = {
          ...setting,
          parentId,
          show: true,
        } as IDrilldownFilterItem
        if (this.searchString()) {
          const searchResult = this.searchInTree(setting, this.searchString())
          result.show = searchResult.found
          result.display = searchResult.value
        }
        if (setting.children) {
          result.children = decorateSettings(setting.children, this.itemToId(setting))
        }
        return result
      })
    }
    return decorateSettings(this.settings())
  })

  flatSettings = computed(() => {
    const traverse = (items: IFilterItem[], parentId: string | null = null): IDrilldownFilterItem[] => {
      const result: IDrilldownFilterItem[] = []
      items.forEach(item => {
        result.push({
          value: item.value,
          field: item.field,
          parentId: parentId,
        })
        if (item.children) {
          result.push(...traverse(item.children, this.itemToId(item)))
        }
      })
      return result
    }
    return traverse(this.settings())
  })

  searchString = signal<string>('')

  constructor() {
    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(),
      filter(chip => chip.filterId === this.filterId()),
    ).subscribe(chip => {
      const data = chip.data as IDrilldownChipData
      this._logger.debug('Chip removed', chip)
      if (!data) {
        this._logger.error('Chip data missing', chip)
      }
      const {
        item,
      } = data
      this.remove(item)
    })
    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.clearAll()
    })
  }

  ngOnInit(): void {
    this.initChips()
    this._buildFilters()
  }

  initChips(): void {
    this.state().forEach(item => {
      this._chipsService.addChip({
        id: this.itemToId(item),
        label: item.value,
        filterId: this.filterId(),
        data: {
          item,
        },
      })
    })
  }

  searchInTree(item: IFilterItem, search: string): {
    found: boolean,
    value: string,
  } {
    const searchLower = search.toLowerCase()
    const itemValueLower = item.value?.toLowerCase() || ''

    if (itemValueLower.includes(searchLower)) {
      const regex = new RegExp(`(${search})`, 'gi')
      const highlightedValue = item.value.replace(regex, '<span class="search-highlight">$1</span>')
      return {
        found: true,
        value: highlightedValue,
      }
    }

    if (item.children) {
      for (const child of item.children) {
        const result = this.searchInTree(child, search)
        if (result.found) {
          return {
            found: true,
            value: item.value,
          }
        }
      }
    }

    return {
      found: false,
      value: item.value,
    }
  }

  checkAnyVisibleInTier(tier: IDrilldownFilterItem[]): boolean {
    return tier.some(item => item.show)
  }

  getFieldConfig(field: string): IDrilldownTierConfig {
    return this.fieldsConfig()[field] ?? {
      parent: null,
      display: field,
      onlyChild: 'show',
    }
  }

  getSectionTitle(section: IDrilldownFilterItem, parent: IDrilldownFilterItem): string {
    const config = this.fieldsConfig()[section.field]
    if (!config) {
      return section.field
    }
    if (typeof config.display === 'string') {
      return config.display
    }
    try {
      return config.display(parent)
    } catch (e) {
      this._logger.error('Invalid display function for section', section, e)
      return section.field
    }
  }

  isSelected(item: IDrilldownFilterItem): boolean {
    return !!this.state().find(i => i.value === item.value && i.parentId === item.parentId)
  }

  handleChange(event: MatCheckboxChange, item: IDrilldownFilterItem, parents: MatCheckbox[]): void {
    if (event.checked) {
      this.add(item)
      if (parents) {
        parents.forEach(parent => {
          parent.checked = true
          this.add(parent.value as unknown as IDrilldownFilterItem)
        })
      }
    } else {
      this.remove(item)
    }
    this._buildFilters()
  }

  add(item: IDrilldownFilterItem): void {
    const newItem = this.buildStateData(item)
    if (!this.state().find(i => i.value === item.value && i.field === item.field && i.parentId === item.parentId)) {
      this.state.update(items => [...items, newItem])
      this._chipsService.addChip({
        id: this.itemToId(item),
        label: item.value,
        filterId: this.filterId(),
        data: {
          item: newItem,
        }
      })
    }
  }

  remove(item: IDrilldownFilterItem): void {
    const children = this.state().filter(i => i.parentId === this.itemToId(item))
    this.state.update(items => items.filter(i => i.value !== item.value || i.field !== item.field || i.parentId !== item.parentId))
    this._chipsService.removeChip(this.itemToId(item))
    if (children && children.length > 0) {
      children.forEach(child => {
        this.remove(child)
      })
    }
  }

  clearAll(): void {
    this.state().forEach(item => {
      this._chipsService.removeChip(this.itemToId(item))
    })
    this.state.set(getEmptyDrilldownFilterState())
  }

  handleSearch(event: Event): void {
    const searchValue = (event.target as HTMLInputElement).value.trim().toLowerCase()
    this.searchString.set(searchValue)
  }

  clearSearch(): void {
    this.searchString.set('')
  }

  buildStateData(item: IDrilldownFilterItem): IDrilldownFilterItem {
    return  {
      value: item.value,
      field: item.field,
      parentId: item.parentId,
    }
  }

  itemToId(item: IFilterItem): string {
    return [item.field, item.value].join(this.delimiter())
  }

  idToItem(id: string): IFilterItem | undefined {
    const pieces = id.split(this.delimiter())
    return this.flatSettings().find(item => item.field === pieces[0] && item.value === pieces[1])
  }

  _buildFilters(): void {
    const fields = Object.keys(this.fieldsConfig())
    // TODO can remove this when all filters are using the filters model instead of static version
    const tempItemToId = (item: IFilterItem) => this.itemToId(item)
    // begin with the first field and recursively build the rest
    this.filters.set(buildJsonLogicForFieldIndex(this.filterService, this.state(), fields, 0, tempItemToId) ?? {})
  }

  // TODO this will be removed when all filters are using the filters model instead of static version
  static buildFilters(
    filterService: FilterService,
    state: IDrilldownFilterState,
    fields: string[], // the fields available in the drilldown in order from highest to lowest
  ): IJsonLogic {
    // begin with the first field and recursively build the rest
    const itemToId = (item: IFilterItem) => [item.field, item.value].join('|')
    return buildJsonLogicForFieldIndex(filterService, state, fields, 0, itemToId) ?? {}
  }
}

const buildJsonLogicForFieldIndex = (
  filterService: FilterService,
  state: IDrilldownFilterState,
  fields: string[], // the fields available in the drilldown in order from highest to lowest
  fieldIndex: number,
  itemToId: (item: IFilterItem) => string,
  parentValue: string | null = null,
): IJsonLogic | null => {
  const field = fields[fieldIndex]
  let entries = state.filter(item => item.field === field)
  if (parentValue) {
    entries = entries.filter(item => item.parentId === parentValue)
  }
  if (entries.length === 0) {
    if (fieldIndex < fields.length - 1) {
      return buildJsonLogicForFieldIndex(filterService, state, fields, fieldIndex + 1, itemToId)
    }
    return null
  }
  return filterService.build(({ and, or, inArray, variable }) => {
    if (fieldIndex === fields.length - 1) {
      return inArray(variable(field), entries.map(entry => entry.value))
    }
    const e = entries.map(entry => {
      const parent = inArray(variable(field), [entry.value])
      const children = buildJsonLogicForFieldIndex(filterService, state, fields, fieldIndex + 1, itemToId, itemToId(entry))
      if (children) {
        return and(parent, children)
      } else {
        return parent
      }
    })
    if (e.length === 1) {
      return e[0]
    }
    return or(...e)
  })
}
