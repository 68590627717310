@if (includeSearch()) {
  <mat-form-field appearance="outline" class="search-field">
    <mat-label i18n="@@drilldownFilter_SearchLabel">Search</mat-label>
    <input
      matInput
      type="text"
      (keyup)="handleSearch($event)"
      [value]="searchString()"
      data-testid="filter-search"
    />
    @if (searchString()) {
      <app-icon-button
        matSuffix
        (click)="clearSearch()"
        data-testid="clear-search"
        matIcon="cancel"
      />
    } @else {
      <mat-icon matSuffix>search</mat-icon>
    }
  </mat-form-field>
}

<ng-container [ngTemplateOutlet]="section" [ngTemplateOutletContext]="{
  items: decoratedSettings(),
  parent: null,
  parentHidden: true,
  parents: [],
}"></ng-container>

<ng-template #section let-items="items" let-parent="parent" let-parentHidden="parentHidden" let-parents="parents">
  @let firstItem = items[0];
  @let fieldConfig = getFieldConfig(firstItem.field);
  @if (items.length === 1 && fieldConfig.onlyChild === 'hide') {
    @if (firstItem.show) {
      <ng-container [ngTemplateOutlet]="section" [ngTemplateOutletContext]="{
        items: firstItem.children,
        parent: firstItem,
        parentHidden: true,
        parents,
      }"></ng-container>
    } @else {
      <ng-container [ngTemplateOutlet]="noResults"></ng-container>
    }
  } @else if (items.length === 1 && fieldConfig.onlyChild === 'title') {
    @if (firstItem.show) {
      <div
        class="section"
        [class.section__child]="parent !== null && !parentHidden"
      >
        <div class="section-title">
          {{firstItem.value}}
        </div>
        <ng-container [ngTemplateOutlet]="section" [ngTemplateOutletContext]="{
          items: firstItem.children,
          parent: firstItem,
          parentHidden: false,
          parents,
        }"></ng-container>
      </div>
    } @else {
      <ng-container [ngTemplateOutlet]="noResults"></ng-container>
    }
  } @else {
    @if (checkAnyVisibleInTier(items)) {
      <div
        class="section"
        [class.section__child]="parent !== null && !parentHidden"
      >
        <div class="section-title">
          {{getSectionTitle(firstItem, parent)}}
        </div>
        @for (item of items; track item) {
          <ng-container [ngTemplateOutlet]="entry" [ngTemplateOutletContext]="{
            item,
            parents,
          }"></ng-container>
        }
      </div>
    } @else {
      <ng-container [ngTemplateOutlet]="noResults"></ng-container>
    } 
  }
</ng-template>

<ng-template #entry let-item="item" let-parents="parents">
  @if (item.show) {
    <div class="entry">
      @let selected = isSelected(item);
      <mat-checkbox
        #checkbox
        type="checkbox"
        (change)="handleChange($event, item, parents)"
        [checked]="selected"
        data-testid="filter-checkbox"
        [value]="item"
      ><span [innerHTML]="item.display ?? item.value"></span></mat-checkbox>
      @if (
        item.children
        && item.children.length
        && (
          (!searchString() && selected)
          || (searchString() && checkAnyVisibleInTier(item.children))
        )
      ) {
        <ng-container [ngTemplateOutlet]="section" [ngTemplateOutletContext]="{
          items: item.children,
          parent: item,
          parents: parents.concat([checkbox]),
        }"></ng-container>
      }
    </div>
  }
</ng-template>

<ng-template #noResults>
  <div class="no-results">
    <div class="no-results__title" i18n="@@drilldownFilter_NoResultsLabel">No Results Found</div>
    <div class="no-results__description" i18n="@@drilldownFilter_NoResultsDescription">
      We didn't find any results for this search.<br />
      Please update your search and try again.
    </div>
  </div>
</ng-template>
