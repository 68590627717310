<div class="location-container">
  <div class="search-button">
    <button
      mat-stroked-button
      (click)="processGeoLocation()"
      [attr.data-qa-id]="'filterLocationGeolocation' + filterId()"
      data-testid="geolocation-button"
    >
      <mat-icon class="material-icons-outlined">location_on</mat-icon>
      <span i18n="@@locationFilter_SearchNearMeLabel">Search near me</span>
    </button>
  </div>
  <div class="zipcode">
    <mat-form-field appearance="outline">
      <mat-label i18n="@@locationFilter_ZipCodeLabel">Zip Code</mat-label>
      <input
        [attr.data-qa-id]="'filterLocationZipCode' + filterId()"
        matInput
        type="text"
        pattern="[a-zA-Z0-9]{5}"
        [value]="state().type === 'zipcode' ? state().value : ''"
        (change)="onZipCodeChanged($event)"
        data-testid="zipcode-input"
      />
    </mat-form-field>
  </div>
  <div class="miles">
    <span class="within" i18n="@@locationFilter_WithinLabel">Within</span>
    <span class="selector">
      <mat-form-field class="select-outlined">
        <mat-select
          [value]="state().miles"
          (selectionChange)="onMilesChanged($event)"
          [attr.data-qa-id]="'filterLocationMiles' + filterId()"
          data-testid="miles-select"
        >
          <mat-option [value]="50" i18n="@@locationFilter_50MilesOption">50 Miles</mat-option>
          <mat-option [value]="100" i18n="@@locationFilter_100MilesOption">100 Miles</mat-option>
          <mat-option [value]="200" i18n="@@locationFilter_200MilesOption">200 Miles</mat-option>
          <mat-option [value]="250" i18n="@@locationFilter_250MilesOption">250 Miles</mat-option>
          <mat-option [value]="500" i18n="@@locationFilter_500MilesOption">500 Miles</mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>
</div>
